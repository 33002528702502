.panelSuite{
    border: 1px solid #ccc;
    cursor: pointer;
}

.panelSuiteActive{
    border: 2px solid #06c;
    border-bottom-width: 4px;
    cursor: pointer;
}

.panelSuite:hover{
    border-color: lightblue;
}