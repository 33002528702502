#diazar-suite-grid a{
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 5px;
}
#diazar-suite-grid a:hover{
  border-color: var( --pf-global--link--Color--hover );
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

section[name="scrolling-section"]{
  height: 100%;
}

section[name="scrolling-section"] .scrolling-container{
  height: 100%;
}
.main-report-stack{
  height: 100%;
}

.report.report-container{
  height: 100%;
  overflow: scroll;
  background-color: var( --base-gray-light );
  border:1px solid var( --base-gray );
}

.report.report-container.hidden{
  height: 0px;
  border: none;
}
.main-report-stack button.report-head-icon{
  font-size: 19px;
  font-weight: bold;
  padding-top: 6px;
  padding-bottom: 0px;
}
#report-buttons{
  position: absolute;
  top: 20px;
  right: 20px;
}
#report-buttons button{
  background-color: rgba(255, 255, 255, 0.50);
}
#report-buttons button:hover{
  background-color: rgba(255, 255, 255, 1);
  transition: all .35s ease-in;
}
.report.report-container .visualizejs._jr_report_container_.jr .jrPage tr td{
  line-height: 10px;
}
.button-zoom-options{
  background-color:var( --base-gray );
  color: white;
  font-size: 12px;
}
.button-zoom-options .pf-c-slider{
  --pf-c-slider__rail-track--Height: 0.15rem;
  --pf-c-slider__thumb--before--Height: 20px;
}
.button-zoom-options .pf-c-slider .pf-c-slider__thumb{
  --pf-c-slider__thumb-Size: 12px;
  --pf-c-slider__thumb--Height: var( --pf-c-slider__thumb-Size );
  --pf-c-slider__thumb--Width: var( --pf-c-slider__thumb-Size );
  --pf-c-slider__thumb--Top: 11px;
}
.button-zoom-options .pf-c-slider .pf-c-slider__rail{
  --pf-c-slider__rail--Padding: 10px;
  --pf-c-slider__rail--PaddingTop: var(--pf-c-slider__rail--Padding);
  --pf-c-slider__rail--PaddingBottom: var(--pf-c-slider__rail--Padding);
}
.button-zoom-options .pf-c-slider button.pf-m-plain{
  --pf-c-button--Padding: 2px;

  --pf-c-button--PaddingTop: var(--pf-c-button--Padding);
  --pf-c-button--PaddingBottom: var(--pf-c-button--Padding);
  --pf-c-button--PaddingRight: calc( 2 * var(--pf-c-button--Padding) );
  --pf-c-button--PaddingLeft:  calc( 2 * var(--pf-c-button--Padding) );
  --pf-c-button--FontSize: 0.75rem;
  --pf-c-button--LineHeight: 0.75rem;
  --pf-c-button--m-plain--Color: white;
}
.button-zoom-options .pf-c-slider button.pf-m-plain:hover{
  --pf-c-button--m-plain--BackgroundColor: rgba(0, 0, 0, 0.25);
}

.button-zoom-options button{
  --pf-c-button--Padding: 2px;

  --pf-c-button--PaddingTop: var(--pf-c-button--Padding);
  --pf-c-button--PaddingBottom: var(--pf-c-button--Padding);
  --pf-c-button--PaddingRight: calc( 2 * var(--pf-c-button--Padding) );
  --pf-c-button--PaddingLeft:  calc( 2 * var(--pf-c-button--Padding) );
  --pf-c-button--FontSize: 0.75rem;
  --pf-c-button--LineHeight: 0.75rem;
  --pf-c-button--m-plain--Color: white;
}

.icon-btn.icon-btn-title{
  padding-bottom: 0px;
  padding-left: 12px;
  padding-right: 12px;

  font-size: 20px;
}

.icon-btn.icon-btn-title .pf-c-badge.indicator{
  vertical-align: top;
  font-size: 10px;
  padding-right: 4px;
  padding-left: 4px;
  min-width: 10px;
}

.tab-subreport{
  height: 75vh;
  border: 1px solid var(--pf-global--disabled-color--200);
  border-top: none;
  padding: 10px;
}

.brand-logo{
  width: 150px !important;
}

.pf-c-nav__item button.pf-c-nav__link{
  text-align: left;
}

.pf-l-split__item.pf-m-fill.pagination-report .pf-c-options-menu{
  width: 0;
  overflow: hidden;
}

.diazar-reports #page-sidebar{
  --sidebar-botton-height: 35px;
  padding-bottom: calc( var(--sidebar-botton-height) + 5 );
  background: var(--pf-c-page__sidebar--BackgroundColor) url(/public/assets/images/logo__diazar_powerby.svg) no-repeat bottom center;
  background-size: auto var( --sidebar-botton-height );
}

.input-controls-draggable{
  position: absolute !important;
  z-index: 1000 !important;

  width: 400px !important;
  height: 600px !important;

  border: 1px solid #ddd;

  transition: opacity 1s;
  opacity: 1;
}

.pf-c-tab-content .list-data{
  height: 300px;
  overflow: scroll;
  border: 1px solid #ccc;
  border-top: 0px solid transparent;
}

.pf-c-tab-content .list-data .list-data-filter{
  padding: 4px 16px;
  backgrond-color: rgb(240, 240, 240);
  height: 0;
  overflow: visible;
}
.pf-c-tab-content .list-data .list-data-filter .pf-c-text-input-group__text{
  font-size: 14px;
}

.pf-c-tab-content .list-data .pf-c-simple-list__item-link{
  margin-top: 1px;
}

.pf-c-tab-content .list-data .pf-c-simple-list__item-link.pf-m-current{
  background-color: var( --pf-global--primary-color--100 );
  color: white;
}

.jr-mOverlay-centroid.jr{
  /* background-color: #e3dcdc; */
}


@media (max-width: 1200px ) {
  .main-report-stack{
    height: calc( 100% - 8px);
  }
}

@media (max-height: 700px) {
  .pf-c-select.pf-m-expanded .pf-c-select__menu{
    max-height: 200px !important;
  }
}